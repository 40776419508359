import { Box, styled } from "@mui/material";
import React, { useContext, useEffect } from "react";

import TurnoPromiseComponent from "../../SDK/FAQ/turnoPromiseComponent";
import { imagesList } from "../../constants";
import { primary, theme } from "../../constants/theme";
import { Context } from "../../context/context";
import { MemoizedHeader } from "../../layouts/header";
import { MemoizedTabHeader } from "../../layouts/tabHeader";
import OperationalCities from "../../modules/Finance/operationalCities";
import MemoizedHomeBenefits from "../../modules/Home/Benefits/benefit";
import DesktopCarousel from "../../modules/Home/DesktopCarousel/DesktopCarousel";
import HomePageFAQs from "../../modules/Home/HomePageFAQs/homePageFAQs";
import ChargingStations from "../../modules/Home/HomePageRevamp/ChargingStations";
import HeaderBanner from "../../modules/Home/HomePageRevamp/HeaderBanner";
import HomePageVehicleList from "../../modules/Home/HomePageRevamp/HomePageVehicleCard";
import TabHeader from "../../modules/Home/HomePageRevamp/TabHeader";
import VideoCallSale from "../../modules/Home/HomePageRevamp/VideoCallSale";
import HappyCustomers from "../../modules/Home/HomePageRevamp/happyCustomers";
import LatestModel from "../../modules/Home/HomePageRevamp/latestModel";
import TestDrive from "../../modules/Home/HomePageRevamp/testDrive";
import VehicleLoan from "../../modules/Home/HomePageRevamp/vehicleLoan";
import TCO from "../../modules/Home/TCO/tco";
import MemoizedWhyMoveAhead from "../../modules/Home/WhyMoveAhead/whyMoveAhead";
import KnowLedgeCards from "../../modules/Home/knowledgeCards/knowledgeCards";
import MemoizedTestimonials from "../../modules/Lead Capture/Testimonials/testimonial";
import { MemoizedReview } from "../../modules/Review/review";
import { MemoizedVisitStore } from "../../modules/Store/homeBanner";
import { sendAnalytics } from "../../utils/analyticsCommon";
import NewLaunchSection from "../../modules/Home/HomePageRevamp/NewLaunchSection";
import { imagesWEBP } from "../../constants/webpImages";

const CustomerHomePage = styled(Box)(() => ({
  ".display-mobile-page": {
    display: "none",
  },

  ".display-desktop-page": {
    display: "block",
  },
  ".loader": {
    textAlign: "center",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "2em",
    color: primary.dark,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },

  [theme.breakpoints.down("md")]: {
    ".display-mobile-page": {
      display: "block",
    },

    ".display-desktop-page": {
      display: "none",
    },
  },
}));

const Home = () => {
  const state = useContext(Context);
  useEffect(() => {
    sendAnalytics("screen_viewed", { screen_name: "home screen" }, "en");
    // eslint-disable-next-line
  }, []);

  return (
    <CustomerHomePage>
      <Box sx={{ pt: 0 }}>
        <Box className="display-mobile-page">
          <MemoizedTabHeader />
          <TabHeader />
         
          <HeaderBanner imageUrl={imagesWEBP?.homePageBannerMobile} />
          <NewLaunchSection/>
          <HomePageVehicleList />
          <HappyCustomers title="Customers in 10+ Cities Bought From Us" />
          <LatestModel />
          <VideoCallSale />
          <TCO />
          <MemoizedVisitStore />
          <TestDrive />
          <VehicleLoan />
          <TurnoPromiseComponent />
          {/* <Carousel /> */}
          {/* <BookEVConsultationForm /> */}
          {/* <HomePageVehicleThumbnail /> */}

          {/* <MemoizedScheduleTestDrive /> */}
          {/* <OperationalCities /> */}

          <KnowLedgeCards />
          {/* <RatingsAndReviews /> */}
        </Box>

        {/* {typeof window !== "undefined" && window.innerWidth > 480 && ( */}
        <Box className="display-desktop-page">
          {/* <LazyLoadImage
            alt="Commercial Electric Gadi"
            style={{ width: "100%", minHeight: "560px" }}
            src={
              typeof window !== "undefined" &&
              window.innerWidth > 480 &&
              imagesList.desktopCarouselBanner4
            }
            visibleByDefault={true}
            fetchpriority={"high"}
            onClick={() => {
              sendAnalytics(
                "view_all_vehicles_desktop_carousel_clicked",
                {},
                "en"
              );
              showCityPopup();
            }}
          /> */}
          <MemoizedHeader/>
          <DesktopCarousel />
          <MemoizedVisitStore />
          <MemoizedHomeBenefits />
          <MemoizedTestimonials
            title={"Our electric heroes"}
            buttonText={"View Vehicles"}
            name={"Shankar"}
            work={"Water Distributor"}
            description={
              "Rs 70 only per day to operate vehicle, zero maintenance charges"
            }
            thumbnail={imagesList.homeElectricHeroThumbnail}
            videoLink={"https://www.youtube.com/embed/QwBDYIgMnNA"}
            type={"piaggio"}
            page={"home"}
            data=""
          />
          <MemoizedWhyMoveAhead
            style={{ backgroundColor: "yellow", height: "56.25em" }}
          ></MemoizedWhyMoveAhead>
          <OperationalCities
            headerInfo={"India's EV Commercial Vehicles Marketplace"}
          />
          <MemoizedReview />
        </Box>
        {/* )} */}

        <HomePageFAQs />
      </Box>
    </CustomerHomePage>
  );
};
export const MemoizedHome = React.memo(Home);
