import { Box, Typography } from "@material-ui/core";
import { PlayCircleFilledWhite } from "@material-ui/icons";
import { Grid, Modal } from "@mui/material";
import { styled } from "@mui/system";
import React, { useContext, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  imagesList,
  offer_id,
  offer_name,
  tag_id,
  tag_name,
} from "../../../constants";
import { primary } from "../../../constants/theme";
import { Context } from "../../../context/context";
import { MemoizedButton } from "../../../SDK/button";
import { sendAnalytics } from "../../../utils/analyticsCommon";

const CustomTestimonials = styled(Box)(({ theme }) => ({
  ".heading-testimonials": {
    fontWeight: "900",
    fontSize: "50px",
    lineHeight: "70px",
    color: primary.main,
  },
  ".customer-name": {
    fontWeight: "300",
    fontSize: "30px",
    lineHeight: "172%",
    color: "#1E1E1E",
    marginTop: "14px",
  },
  ".customer-desc": {
    marginTop: "100px",
    fontWeight: "600",
    fontSize: "35px",
    lineHeight: "50px",
    color: "#1E1E1E",
    width: "75%",
    fontStyle: "italic",
  },
  ".image-box": {
    maxWidth: "983px",
    maxHeight: "649px",
    width: "100%",
    height: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  ".image-box svg": {
    width: "100px",
    height: "100px",
    color: primary.white,
  },
  ".youtube-video": {
    width: "500px",
    height: "400px",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: "24px",
    ".heading-testimonials": {
      fontSize: "20px",
      lineHeight: "32px",
    },
    ".image-box": {
      height: "400px",
      backgroundPosition: "center",
    },
    ".customer-name": {
      fontSize: "18px",
      lineHeight: "24px",
      marginTop: "10px",
    },
    ".customer-desc": {
      fontSize: "16px",
      lineHeight: "20px",
      width: "100%",
      marginTop: "16px",
    },
    ".testimonial-human": {
      width: "20px",
      height: "20px",
      marginTop: "10px",
    },
  },
}));

const Testimonials = ({
  title,
  buttonText,
  name,
  work,
  description,
  thumbnail,
  videoLink,
  page,
  data,
}) => {
  const [open, setOpen] = useState(false);
  const { showCityPopup } = useContext(Context);

  return (
    <CustomTestimonials>
      <Grid
        container
        spacing={2}
        sx={
          page === "home"
            ? {
                padding: { xs: "16px", md: "100px 50px 100px 150px" },
                background: "#FEF8E6",
              }
            : { padding: { xs: "16px", md: "70px" } }
        }
      >
        <Grid
          sx={{ display: "block", paddingRight: "20px" }}
          item
          xs={12}
          md={6}
        >
          <Typography
            variant="h2"
            className="heading-testimonials"
            style={page === "home" ? { width: "100%" } : {}}
          >
            {title}{" "}
            {page === "home" && (
              <LazyLoadImage
                src={imagesList.homeElectricHeroHumanEmoji}
                effect="opacity"
                className="testimonial-human"
                alt="electric heroes"
              />
            )}
          </Typography>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Typography className="customer-desc">
              &quot;{description}&quot;
            </Typography>
            <Typography className="customer-name">
              {name}, {work}
            </Typography>
            {page === "home" && (
              <MemoizedButton
                content={buttonText}
                sx={{ width: { xs: "100%", md: "50%" }, mt: "54px" }}
                handleClick={() => {
                  showCityPopup();
                  let analytics_popup = {
                    trigger_button: "testimonials",
                  };
                  sendAnalytics("city_popup_opened", analytics_popup, "en");
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid sx={{ display: "block" }} item xs={12} md={6}>
          <Box
            onClick={() => {
              let payload = {
                video_name: name,
                video_id: name,
                video_url: videoLink,
                is_hilighted: true,
              };
              sendAnalytics("feedback_video_clicked", payload, "en");
              setOpen(true);
            }}
            className="image-box"
            style={{
              backgroundImage: `url(${thumbnail})`,
            }}
          >
            <PlayCircleFilledWhite />
          </Box>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <Typography className="customer-desc">
              &quot;{description}&quot;
            </Typography>
            <Typography className="customer-name">
              {name}, {work}
            </Typography>
            <MemoizedButton
              content={buttonText}
              sx={{ width: { xs: "100%", md: "50%" }, mt: "16px" }}
              handleClick={() => {
                if (page === "home") {
                  let payload = {
                    button_name: "view vehicle",
                    button_id: 3,
                  };
                  sendAnalytics("view_all_vehicles_clicked", payload, "en");
                  showCityPopup();
                  let analytics_popup = {
                    trigger_button: "testimonials",
                  };
                  sendAnalytics("city_popup_opened", analytics_popup, "en");
                } else {
                  window.scrollTo &&
                    window.scrollTo({
                      top:
                        document.getElementById("booking-form").offsetTop - 100,
                      behavior: "smooth",
                    });
                  let analytics_payload = {
                    vehicle_name: data?.name ?? "",
                    vehicle_id: data?.vehicle_id ?? "",
                    emi_shown: data?.emiPrice ?? "",
                    price_shown: data?.price ?? "",
                    offer_name: offer_name,
                    offer_id: offer_id,
                    tag_name: tag_name,
                    tag_id: tag_id,
                    button_trigger_location: "testimonial",
                  };
                  sendAnalytics(
                    "fill_the_form_button_clicked",
                    analytics_payload,
                    "en"
                  );
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="modal-details"
        sx={{ top: "30%" }}
      >
        <Box
          className="modal-video"
          sx={{
            width: { xs: "95%", md: "500px" },
            margin: "auto",
            height: { xs: "300px", md: "400px" },
          }}
        >
          <iframe
            title={"testimonial video"}
            className="youtube-video"
            maxWidth="500px"
            maxHeight="400px"
            width="100%"
            height="100%"
            src={videoLink}
            frameborder="0"
            allowfullscreen
          ></iframe>
        </Box>
      </Modal>
    </CustomTestimonials>
  );
};

const MemoizedTestimonials = React.memo(Testimonials);
export default MemoizedTestimonials;
