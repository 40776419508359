import { Box, styled,Grid, Stack, Typography } from "@mui/material";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "swiper/css";
import "swiper/css/pagination";
import { Context } from "../../../context/context";

import { primary } from "../../../constants/theme";
import { imagesWEBP } from "../../../constants/webpImages";
import { MemoizedButton } from "../../../SDK/button";
import { sendAnalytics } from "../../../utils/analyticsCommon";
const CustomModelCard = styled(Box)(({ theme }) => ({

  ".vehicle-box": {
    background: primary?.white,
  },

  ".vehicle-image-box": {
    // width: "100%",
    minHeight: "110px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0px 5px",
  },
  ".vehicle-info-box": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    // padding: "0 10px 0 10px",
  },
  ".logo-box": {
    width: "100px",
    height: "30px",
    display: "flex",
    // padding:"0px 10px 0px 10px",
  },
  ".logo-image": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },

  ".name-text": {
    fontSize: "12px",
    padding: "5px 10px 0px",
    fontWeight: 700,
  },
  ".desc-text": {
    fontSize: "12px",
    padding: "5px 10px 5px",
  },
  ".launch-image": {
    width: "100%",
  },
  ".book-test-drive-button": {
    borderRadius: "8px",
    boxShadow: "none",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
  },

  ".check-vehicle-button": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      borderRadius: "8px",
      boxShadow: "none",
      "&:hover": {
        color: primary.white,
        background: primary.main,
      },
  },
  ".grid-item-style": {
    display: "flex",
    justifyContent: "center",
    borderRadius: "3.923px",
    background: "#F5F8F8",
    padding: "10px 20px 10px 20px",
  },

  ".grid-container-style": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 10px",

  },
  ".vehicleDetails": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  },
  ".vehicleDetailsHeader": {
    fontSize: "12px",
    color: "#333",
    fontWeight: 500,
  },
  ".vehicleDetailsdata": {
    fontSize: "14px",
    fontWeight: 600,
  },


  [theme.breakpoints.down("md")]: {
    ".vehicle-image": {
      height: "120px",
      //width: "100%",
    },
  },
}));

const NewLaunchModelCard = ({ data, hideButton }) => {
  //   cons;
  //const navigate = useRouter();

  const { showLoanPopup } = useContext(Context);
  const navigate = useRouter();
  const brandName = data?.brand;

  const name = brandName.charAt(0).toUpperCase() + brandName.slice(1);

  return (
    <CustomModelCard>
      <Box
        className="vehicle-box"
        onClick={() => {
          let payload = {
            vehicle_id: data?.id,
            vehicle_name: data?.name,
          };
          sendAnalytics("v2_view_vehicle_newLaunch_ev_model", payload, "en");

          const loanPayload = {
            brand: data?.brand,
            formTrigger: "v2_view_vehicle_newLaunch_ev_model",
            vehicle_id: data?.vehicle_path_id,
            headingText: "Enter Your Mobile Number",
            CTAText: "Get On Road Price",
            vehicle_type:data?.type
          };

          if (localStorage?.getItem("phoneInfo") === null) {
            showLoanPopup(loanPayload);
          } else {
            navigate.push(
              `/electric-passenger-vehicles/${data?.brand}/${data?.vehicle_path_id}`
            );
          }

        }}
      >
        <Box className="vehicle-image-box">
          <LazyLoadImage
            src={data?.newLaunchImage}
            alt={data?.name}
            className="launch-image"
          />
          </Box>
          <Box className='vehicle-info-box'>
            <Box className='logo-box'>
              <LazyLoadImage
                src={data?.vehicle_logo}
                alt={data?.name}
                className="logo-image"
                />
              </Box>
              <Typography className="name-text">{data?.name}</Typography>
          </Box>
          <Grid
        Container
        className="grid-container-style"
        //  style={{ paddingTop: "0px", height: "60px" }}
        rowSpacing={2}
      >
        <Grid item xs={4} className="grid-item-style">
          <Stack className="vehicleDetails">
            <Typography className="vehicleDetailsHeader">Top Range</Typography>
            <Typography className="vehicleDetailsdata">
              {data?.range}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4} className="grid-item-style">
          <Stack className="vehicleDetails">
            <Typography className="vehicleDetailsHeader">Cost/100Km</Typography>
            <Typography className="vehicleDetailsdata">
              {data?.costPer100Km}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4} className="grid-item-style">
          <Stack className="vehicleDetails">
            <Typography className="vehicleDetailsHeader">Top Speed</Typography>
            <Typography className="vehicleDetailsdata">
              {data?.topSpeed}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
       
      </Box>
      <Box
        style={{
          display: hideButton ? "none" : "flex",
          justifyContent: "center",
          margin: "10px",
        }}
      >
        <MemoizedButton
          className="check-vehicle-button"
          content={`View Vehicle`}
          handleClick={() => {
            let payload = {
              vehicle_id: data?.id,
              vehicle_name: data?.name,
            };
            sendAnalytics("v2_view_vehicle_newLaunch_ev_model", payload, "en");

            const loanPayload = {
              brand: data?.brand,
              formTrigger: "v2_view_vehicle_newLaunch_ev_model",
              vehicle_id: data?.vehicle_path_id,
              headingText: "Enter Your Mobile Number",
              CTAText: "Get On Road Price",
              vehicle_type:data?.type
            };
            if (localStorage?.getItem("phoneInfo") === null) {
              showLoanPopup(loanPayload);
            } else {
              navigate.push(
                `/electric-passenger-vehicles/${data?.brand}/${data?.vehicle_path_id}`
              );
            }
            //navigate.push(`/vehicles/${data?.brand}`);
          }}
        />
      </Box>
    </CustomModelCard>
  );
};

export default NewLaunchModelCard;
